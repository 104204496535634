<template>
  <div
    class="all"
    :class="[
      changeClass1 == 2 ? 'headerClass2' : 'headerClass1',
      changeClass1 == 2 ? 'fadeClass2' : 'fadeClass1',
    ]"
  >
    <div class="headers row-center flex" ref="tabHeight">
      <div class="header row-center flex">
        <img :src="src" alt="" class="logo" @click="logo" />
        <div class="top flex row-between row-end">
          <div class="tabs flex row-between">
            <div
              class="tab"
              :class="tabIndex1 == index ? 'tabActive' : ''"
              v-for="(item, index) in tabList"
              :key="index"
              @click="tab(item.path)"
            >
              <!-- <div class="tab" v-for="(item, index) in tabList"
                            :key="index" @click="tab(item.path)"> -->
              {{ $t('message.headers.' + item.name) }}
            </div>
            <div>
              <!-- <Button3 /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import Button3 from "@/components/com/button03.vue"; //头部
export default {
  components: {
    //Button3
  },
  data() {
    return {
      headerClass: '', //有三种样式，根据传入的changeClass1来判断显示那种样式
      fadeClass: '', //淡入淡出动画
      src: require('../../assets/20230905/LOGO.svg'), //同headerClass
      tabList: this.$store.state.tabList, //头部tab内容
      windowWidth: document.body.clientWidth,
    }
  },
  props: {
    changeClass1: Number, //有三种样式，显示第几种
    tabIndex1: Number, //tab高亮
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.windowWidth = document.documentElement.clientWidth // 宽
      })()
    }
  },
  watch: {
    // changeClass1(newVal) {
    //     console.log('newVal', newVal);
    //     if (newVal == 1) {
    //         this.headerClass = "headerClass1";
    //         this.fadeClass = "fadeClass1";
    //     } else {
    //         this.headerClass = "headerClass2";
    //         this.fadeClass = "fadeClass2";
    //     }
    // }
    windowWidth(val) {
      console.log('val', val)
    },
  },
  methods: {
    // tab跳转
    tab(path) {
      console.log('path', path)
      if (path == 'dealer') {
        window.location.href = 'https://store.blucapsport.com/pages/dealer'
        //window.open('https://store.blucapsport.com/pages/dealer','_blank')
      } else if (path == '/app') {
        window.location.href = 'https://blucapsport.com/app'
      } else if (path == '/open_lab') {
        window.location.href =
          'https://store.blucapsport.com/products/blucap-moto-development-kit'
      } else {
        this.$router.push({ path: path })
      }
    },
    // 点击logo跳转到首页
    logo() {
      this.$router.push({ path: '/' })
    },
  },
}
</script>

<style lang="less" scoped>
.fadeClass1,
.fadeClass2 {
  transition: all 0.6s;
}

.headerClass1 {
  color: #ffffff;
}

.headerClass2 {
  color: #333;
  background: rgba(0, 0, 0, 0.41);
  backdrop-filter: blur(68px);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.all {
  width: 100%;
  margin: 0 auto;
  position: fixed;
  top: 0;
  z-index: 100;

  // box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.08);

  .headers {
    max-width: 1920px;
    margin: 0 auto;
    // padding: 15px 0px;
    padding-top: 13px;
    padding-bottom: 13px;
    .logo {
      width: 91px;
      margin-top: -6px;
      cursor: pointer;
    }

    .top {
      width: 1200px;
      color: rgba(255, 255, 255, 0.7);
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      position: relative;
      font-family: League Spartan;

      .tabs {
        .tab {
          cursor: pointer;
          padding: 1px 24px;
          line-height: 34px;
        }

        .tabActive {
          color: #ffffff;
        }
      }
    }
  }
}

.black {
  background-color: rgba(0, 0, 0, 1);
}
</style>

<!-- <style lang="less" scoped>
@media screen and(max-width: 1919px) and(min-width:1200px) {
  .all {
    width: 100%;
    margin: 0 auto;
    position: fixed;
    top: 0;
    z-index: 100;
    //zoom: 0.947;
    // box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.08);

    .headers {
      width: 1440px;
      margin: 0 auto;
      //padding: 15px 0px;
      padding-top: 13px;
      padding-bottom: 13px;
      .logo {
        width: 91px;
        margin-top: -6px;
        cursor: pointer;
      }

      .top {
        width: 1289px;
        color: rgba(255, 255, 255, 0.7);
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        position: relative;
        font-family: League Spartan;

        .tabs {
          .tab {
            cursor: pointer;
            padding: 1px 24px;
            line-height: 34px;
          }

          .tabActive {
            color: #ffffff;
          }
        }
      }
    }
  }

  .black {
    background-color: rgba(0, 0, 0, 1);
  }
}
</style> -->

<!-- <style lang="less" scoped>
@media screen and(max-width: 1199px) {
  .all {
    width: 100%;
    margin: 0 auto;
    position: fixed;
    top: 0;
    z-index: 100;
    // box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.08);

    .headers {
      max-width: 1440px;
      margin: 0 auto;
      padding-top: 13px;
      padding-bottom: 13px;

      .logo {
        width: 91px;
        margin-top: -6px;
        cursor: pointer;
      }

      .top {
        max-width: 1289px;
        color: rgba(255, 255, 255, 0.7);
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        position: relative;
        font-family: League Spartan;

        .tabs {
          .tab {
            cursor: pointer;
            padding: 1px 24px;
            line-height: 34px;
          }

          .tabActive {
            color: #ffffff;
          }
        }
      }
    }
  }

  .black {
    background-color: rgba(0, 0, 0, 1);
  }
}
</style> -->
