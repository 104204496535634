<template>
  <div>
    <div
      class="header"
      ref="tabHeight"
      :class="[headerClass, fadeClass, showTab ? 'backgroundWhite' : '']"
    >
      <div class="top flex row-between">
        <img :src="logo" alt="" class="logo" v-show="!showTab" />
        <div class="flex row-between" v-show="!showTab">
          <div>
            <!-- <Button4 /> -->
          </div>
          <img :src="san" alt="" class="scan" @click="showTabs" />
        </div>
        <div class="logo1" v-show="showTab"></div>
        <img
          :src="close"
          alt=""
          class="close"
          v-show="showTab"
          @click="showTabs"
        />
      </div>
      <!-- 一级tab -->
      <div class="tabs flex column-between" v-show="showTab">
        <div
          class="tab"
          :class="tabIndex1 == index ? 'tabActive' : ''"
          v-for="(item, index) in tabList"
          :key="index"
          @click="tab(item.path, index)"
        >
          {{ $t('message.headers.' + item.name) }}
        </div>
      </div>
    </div>

    <!-- <div class="show-text">
            MSRP: $399, The first batch of pre-orders will be shipped in mid-2024 in sequential order. 
        </div> -->
  </div>
</template>
<script>
//import Button4 from '@/components/com/button04.vue' //头部
export default {
  components: {
    //Button4,
  },
  props: {
    changeClass1: Number, //有三种样式，显示第几种
    tabIndex1: Number,
  },
  data() {
    return {
      headerClass: '',
      fadeClass: '',
      logo: require('../../assets/20230905/LOGO.svg'),
      but: require('../../assets/20230905/buttones.svg'),
      san: require('../../assets/20230905/san2.svg'),
      close: require('../../assets/20230905/X.svg'),
      tabList: this.$store.state.tabList,
      showTab: false, //一级tab
      isMark: false,
      popupStatus: false, //是否禁止底层滑动
      isShow: false,
      isshowbuy: false,
    }
  },
  created() {
    // this.headerClass = "headerClass3";
  },
  watch: {
    changeClass1(newVal) {
      console.log('newVal', newVal)
      if (newVal == 1) {
        this.headerClass = 'headerClass1'
        this.fadeClass = 'fadeClass1'
        this.isshowbuy = false
      } else {
        this.headerClass = 'headerClass2'
        this.fadeClass = 'fadeClass2'
        this.isshowbuy = true
      }
    },
    // 监听是否禁止底层滑动
    popupStatus(val) {
      if (val) {
        document.body.style.overflow = 'hidden'
        document.addEventListener('touchmove', this.preD, { passive: false }) // 禁止页面滑动
      } else {
        document.body.style.overflow = '' // 出现滚动条
        document.removeEventListener('touchmove', this.preD, {
          passive: false,
        })
      }
    },
  },
  methods: {
    // 默认事件
    // preD(e) {
    //     e.preventDefault();
    // },
    showTabs() {
      this.showTab = !this.showTab
      this.isMark = !this.isMark
      this.popupStatus = !this.popupStatus
    },
    // tab跳转
    tab(path) {
      this.showTab = false
      document.body.style.overflow = '' // 出现滚动条
      document.removeEventListener('touchmove', this.preD, {
        passive: false,
      })
      if (path == 'dealer') {
        window.location.href = 'https://store.blucapsport.com/pages/dealer'
        //window.location.href='https://store.blucapsport.com/pages/dealer';
      } else if (path == '/app') {
        window.location.href = 'https://blucapsport.com/app'
      } else if (path == '/open_lab') {
        window.location.href =
          'https://store.blucapsport.com/products/blucap-moto-development-kit'
      } else {
        this.$router.push({ path: path })
      }
      //this.$router.push({ path: path });
    },
  },
}
</script>

<style lang="less" scoped>
.fadeClass1,
.fadeClass2 {
  // transition: all 0.6s;
}

.headerClass1 {
  color: #ffffff;
}

.headerClass2 {
  color: #333;
  background: rgba(0, 0, 0, 0.41);
  backdrop-filter: blur(68px);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 105;

  .top {
    width: 100%;
    padding: 0.13rem 0.3rem;

    .logo {
      width: 0.91rem;
    }
    .logo1 {
      width: 0.91rem;
      height: 0.08rem;
    }
    .btimg {
      width: 0.91rem;
      margin-right: 0.21rem;
    }

    .scan {
      width: 0.29rem;
      margin-left: 0.21rem;
    }
  }
}

.backgroundWhite {
  height: 100%;
  background: rgba(0, 0, 0, 0.41);
  backdrop-filter: blur(68px);

  .close {
    width: 0.29rem;
    //margin-left: 3rem;
  }
}

// tabs
.tabs {
  text-align: left;
  z-index: 105;
  position: relative;
  margin-left: 0.24rem;
  margin-top: 0.37rem;

  .tab {
    width: 100%;
    font-size: 0.16rem;
    font-weight: 400;
    line-height: 0.5rem;
    font-family: League Spartan;
    font-size: 32px;
    font-style: normal;
    font-weight: 461;
    // line-height: 120%;
    color: rgba(255, 255, 255, 0.5);
    min-height: 0.64rem;
  }

  // 改变样式
  .tabActive {
    color: #fff;

    font-weight: 600;
  }
}

.show-text {
  background-color: #236192;
  padding: 0.12rem 0.3rem;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: League Spartan;
  font-size: 0.16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #fff;
  margin-top: 0.6rem;
}
</style>
